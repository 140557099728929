//color-variable
$color-primary: #250c6d;
$color-primary-fade: #68649c;
$color-black: #212121;
$color-white: #fff;
$color-main-button: #e25e53;

$dark: #333;
$primary: #E25E52;
$grey: #828282;
$white: #fff;

//font-variable
$main-font-weight: 600;
