#ZoomMeetingCalendarModal {
  max-width: 1000px;
  .rbc-ellipsis,
  .rbc-event-label,
  .rbc-row-segment .rbc-event-content,
  .rbc-show-more {
    text-overflow: unset;
    white-space: break-spaces;
  }
  .rbc-toolbar .rbc-toolbar-label {
    padding: 0px 135px 0 0;
    font-weight: bold;
  }
}

.rbc-event {
  border: 1px solid #000 !important;
  .rbc-event-label {
    font-size: 9px;
  }
}

.rbc-event-content {
  font-size: 10px;
}

.rbc-event-content {
  font-weight: 600;
}

.calendar-colorbox {
  position: relative;
}

.calendar-header {
  position: relative;
  border-bottom: 1px solid #ccc !important;
  h3 {
    width: 100%;
    text-align: center;
    font-weight: 300;
    color: #7b7b7b;
    font-size: 24px;
    padding: 0;
    margin: 0;
    color: #e25e52;
  }
  span {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #6f6f6f;
    cursor: pointer;
  }
  .join-sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px 0 0;
    h6 {
      margin: 0;
    }
  }
}

.rbc-header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}
