//TRACK ORDERS
.track-section {
  .track-section-head {
    margin-bottom: 30px;
    h2 {
      margin: 0px;
      color: #000000;
      text-transform: uppercase;
      font-size: 35px;
      font-weight: 700;
    }
    p {
      margin-bottom: 0px;
      color: #000000;
      font-size: 18px;
    }
  }

  .no-order {
    text-align: center;
  }
}

//track-box
.track-box {
  border: 1px solid #d1d1d1;
  padding: 10px;
  margin-bottom: 15px;
}

//vendor-table
.vendor-table {
  .vendor-table-head {
    .entries-drop {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      label {
        color: #000000;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 10px;
        margin-bottom: 0px;
      }
      .entries-controls {
        border: 1px solid #d1d1d1;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      p {
        color: #000000;
        font-size: 13px;
        margin-bottom: 0px;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
      }
    }
    .vendor-table-head-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      .vendor-table-search {
        margin-bottom: 15px;
        .form-control {
          border: 1px solid #d1d1d1;
          border-radius: 0px;
          font-size: 13px;
          text-transform: uppercase;
          font-weight: 500;
          width: auto;
          height: 25px;
        }
      }
    }
  }
  .vendor-table-body {
    .table {
      thead {
        background-color: rgba(226, 94, 82, 0.1);
        tr {
          td {
            color: #000;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            vertical-align: middle;
          }
        }
      }
      tbody {
        tr {
          td {
            color: #000000;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            vertical-align: middle;
            position: relative;
            .notify-dispute {
              position: absolute;
              width: 15px;
              height: 15px;
              border-radius: 100px;
              background-color: #ff0000;
              top: 16px;
              right: 26px;
            }
            p {
              margin-bottom: 0px;
            }
            a {
              color: #e25e52;
              &:hover {
                color: #000000;
              }
            }
            .vendor-table-btn {
              .orange-outline-btn {
                min-width: auto;
                font-size: 12px;
                text-transform: capitalize;
                padding: 2px 20px;
              }
            }
          }
        }
      }
    }
  }
}

//media css
@media only screen and (max-width: 767px) {
  //vendor-table
  .vendor-table {
    .vendor-table-head {
      .vendor-table-head-right {
        justify-content: end;
      }
    }
  }
}
