.icon-most {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 10px;
}

.icon-most img {
    border: none;
    width: 100%;
    height: auto;
    margin: 0;
}

.icon-fast {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0 !important;
}

.abcRioButton  {
    position: absolute !important;
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
    opacity: 0 !important;
}