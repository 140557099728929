@import "../../Styles/variables.scss";

.add-product-page {
    padding-top: 40px;
    padding-bottom: 40px;
    .page-heading {
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        gap: 10px;

        h2 {
            font-size: 30px;
            font-weight: 700;
            color: $dark;
            margin-bottom: 0px;
        }

        .back-btn {
            min-width: 60px;
        }
    }

    .form-group{
        label{
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 400;
            color: $dark;
            margin-bottom: 5px;
        }

        .form-control{
            border-color: $grey;
            border-radius: 10px;
            padding: 12px 15px;
            font-size: 16px;
            color: $dark;
            height: 50px;
            width: 100%;
            font-weight: 400;
            sup{
                font-size: 14px;
            }
        }
        textarea{
            height: auto !important;
        }
        .react-tel-input{
            .flag-dropdown{
                border-radius: 8px 0 0 8px;
            }
            .selected-flag:focus, .selected-flag:hover{
               background-color: transparent;
            }

            .flag-dropdown.open .selected-flag{
               background-color: transparent;
            }
        } 
    }

    .form-inner-hrading{
        margin-bottom: 15px;
        .name{
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            h4{
                font-size: 16px;
                font-weight: 600;
                color: $dark;
                margin-bottom: 0px;
            }
        }
        .tooltip-box{
            svg{
                width: 18px;
                height: 18px;
                min-width: 18px;
            }
        }

        .inline-radio{
            display: flex;
            gap: 20px;
        }
    }

     //basic-multi-select
     .basic-multi-select{
        .select__control{
            border-color: $grey;
            min-height: 50px;
            border-radius: 10px;
            box-shadow: none;
        }
    }

    //upload-box
    .upload-box{
        border-radius: 10px;
        border: 1px dashed $grey;
        position: relative;
        
        input{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
        }
        .box-content{
            padding: 30px 20px;
            text-align: center;
            .uploaded-image{
                width: 100%;
                height: 250px;
                object-fit: contain;
                margin: 0px auto 20px;
                display: block;
            }
            svg{
                width: 24px;
                height: 24px;
                min-width: 24px;
                opacity: 0.5;
            }
            p{
                font-size: 16px;
                font-weight: 400;
                color: $grey;
                margin-bottom: 0px;
            }
            span{
                font-size: 16px;
                font-weight: 400;
                color: $grey;
                margin-bottom: 0px;
            }
        }
    }

    //input-note
    .input-note{
        p{
            font-size: 14px;
            font-weight: 400;
            color: $grey;
            margin-bottom: 0px;
        }
        &.primary-note{
            display: inline-block;
            border-radius: 40px;
            background: rgba(226, 94, 82, 0.10);
            padding: 10px 15px;
            p{
                color: $dark;
            }
        }
    }

    //add-product-btn
    .add-product-btn{
        button{
            padding: 0px;
            margin: 0px;
            border: none;
            outline: none;
            box-shadow: none;
            color: $primary;
            display: flex;
            align-items: center;
            gap: 10px;
            text-transform: capitalize;
            svg{
                width: 18px;
                height: 18px;
                min-width: 18px;
            }
            &:hover{
               background-color: transparent;
            }
        }
    }

    //tooltip-box
    .tooltip-box {
        svg {
            width: 18px;
            height: 18px;
            min-width: 18px;
        }
    }

    .white-card{
        padding: 20px;
        background-color: $white;
        margin-top: 20px;
        border: 1px solid #D8D8D8;

        .action-btn {
            display: flex;
            gap: 15px;
        }
    }

    .select-category-section{
        .section-heading{
            margin-bottom: 20px;
            h3{
                font-size: 20px;
                font-weight: 600;
                color: $dark;
                margin-bottom: 0px;
            }
        }
    }


    .category-card{
        border-radius: 10px;
        border: 1px solid rgba(51, 51, 51, 0.12);
        background: $white;
        overflow: hidden;
        margin-bottom: 30px;
        .card-img{
            img{
                width: 100%;
                height: 250px;
                object-fit: cover;
                object-position: top;
            }
        }
        .card-content{
            padding: 15px;
            .name-inline{
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 10px;
                .name{
                    width: calc(100% - 40px);
                    h4{
                        font-size: 20px;
                        font-weight: 700;
                        color: $dark;
                        margin-bottom: 0px;
                    }
                }
                .custon-radio{
                    margin: 0px;
                    margin-top: -16px;
                }
            }
            p{
                font-size: 16px;
                font-weight: 400;
                color: $dark;
                margin-bottom: 0px;
            }
        }
    }

    //upload-list
    .upload-list{
        display: flex;
        align-items: center;
        gap: 20px 40px;
        flex-wrap: wrap;
        .upload-item{
            display: flex;
            gap: 10px;
            align-items: center;
            position: relative;
            .item-img{
                img{
                    width: 120px;
                    height: 120px;
                    min-width: 120px;
                    border-radius: 10px;
                    background-color: rgba(51, 51, 51, 0.12);
                    object-fit: contain;
                }
            }
            &:after{
                content: "";
                position: absolute;
                right: -20px;
                top: 0px;
                height: 100%;
                width: 1px;
                background-color: rgba(51, 51, 51, 0.12);
            }

            &:nth-last-child(1){
                &:after{
                    display: none;
                }
            }

            .link-pera{
                cursor: pointer;
                margin: 0px 0px 5px;
                &:hover{
                    color: $primary;
                }
            }
        }
    }
}

iframe{
    display: none;
}

//slots-card-list
.slots-card-list{
    display: flex;
    //align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    .slots-card{
        border: 1px solid rgba(51, 51, 51, 0.12);
        width: 100%;
        max-width: 160px;
        .slots-card-name{
            background-color: $dark;
            padding: 8px 15px;
            text-align: center;
            h3{
                font-size: 16px;
                font-weight: 400;
                color: $white;
                margin-bottom: 0px;
            }
        }
        .slots-card-mid{
            border-bottom: 1px solid rgba(#000, 0.10);
            padding: 5px 0px;
            .breaker{
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                color: $dark;
                margin-bottom: 0px;

            }
            .react-time-picker{
                border: 0px;
                .react-time-picker__wrapper{
                    .react-time-picker__inputGroup{
                        text-align: center;
                    }
                }
            }
            .card-date{
                margin-bottom: 5px;
            }
            .delete-btn{
                text-align: center;
                padding-bottom: 2px;
                p{
                    color: $primary;
                    margin: 0px;
                    cursor: pointer;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    margin: 0px auto;
                    font-size: 14px;
                    i{
                        color: $primary;
                    }

                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
        .add-slots-btn{
            padding: 8px 0px;
            p{
                color: $white;
                margin: 0px;
                cursor: pointer;
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: center;
                width: fit-content;
                margin: 0px auto;
                background-color: #333333;
                padding: 5px 15px;
                border-radius: 0x;
                font-size: 14px;
                i{
                    color: $white;
                }
                &:hover{}
            }
        }

        &.active-card{
            border-color: $primary;
            .slots-card-name{
                background-color: $primary;
            }
        }
    }

 
}

//crop-img-box
.crop-img-box{
    .box-img{
        align-items: center;
        border: 1px solid rgba(51, 51, 51, 0.12);
        display: flex;
        height: 260px;
        justify-content: center;
        overflow: hidden;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

//one-on-one-meeting
.one-on-one-meeting{
    border: 1px solid rgba(51, 51, 51, 0.12);
    margin-bottom: 20px;
    padding: 15px 15px 0px;
}

//group-session-section
.group-session-section{
    border: 1px solid rgba(51, 51, 51, 0.12);
    margin-bottom: 20px;
    padding: 15px 15px 0px;
}

//subscription-category-box
.subscription-category-box{
    border: 1px solid rgba(51, 51, 51, 0.12);
    margin-bottom: 20px;
    padding: 15px 15px 0px;
}