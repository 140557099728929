@import "../../Styles/variables.scss";

.admin-card {
  .messages-box {
    .message-search {
      position: relative;
      input {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid;
        font-size: 14px;
        padding: 24px 15px;
        padding-right: 34px;
      }
      span {
        position: absolute;
        top: 13px;
        right: 12px;
        color: #e25e52;
        cursor: pointer;
      }
    }
    .main-side-select {
      height: 510px;
      overflow-y: auto;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        display: flex;
        padding: 10px;
        align-items: center;
        border-bottom: 1px solid #d1d1d1;
        background-color: #f8f8f8;
        cursor: pointer;
        &.active {
          background-color: #fcefed;
          .username-desc {
            h6 {
              font-weight: 700;
            }
          }
        }
        &.differ-username {
          display: block;
        }
        .dayseparation {
          text-align: center;
          position: relative;
          margin-bottom: 15px;
        }
        .dayseparation:after {
          content: "";
          position: absolute;
          background-color: #00000029;
          width: 100%;
          height: 1px;
          left: 0px;
          top: 11px;
        }

        .dayseparation span {
          display: inline-block;
          font-size: 10px;
          line-height: normal;
          color: #404f8c;
          padding: 0px 10px;
          background-color: #fff;
          position: relative;
          z-index: 1;
        }
        .flex-image-username {
          display: flex;
          align-items: center;
          margin-left: 60%;
          max-width: 50%;
        }

        .flex-image-vendor {
          display: flex;
          align-items: flex-start;
          max-width: 45%;
        }
        .username-short {
          width: 40px;
          height: 40px;
          min-width: 40px;
          min-height: 40px;
          border: 1px solid #707070;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          font-weight: 700;
          color: #707070;
          margin-right: 10px;
        }
        .username-desc {
          h6 {
            text-transform: uppercase;
            margin: 0;
            font-size: 14px;
            &.active-head {
              font-weight: 700;
            }
          }
          p {
            font-size: 12px;
            margin: 0;
          }
        }

        .username-desc-map-image {
          display: flex;
          flex-flow: column;
          align-items: flex-end;
          &.left-chat-map {
            align-items: flex-start;
          }
          .chat-img-desc-sub {
            margin: 15px 0;
            img {
              width: 220px;
              height: 220px;
              object-fit: cover;
              object-position: center;
              border: 2px solid $color-main-button;
              border-radius: 15px 15px 0 15px;
            }
          }

          .chat-img-desc-sub-vendor {
            margin: 20px 0;
            img {
              width: 220px;
              height: 220px;
              object-fit: cover;
              object-position: center;
              border: 2px solid $color-main-button;
              border-radius: 15px 15px 15px 0;
            }
          }

          .download-doc-preview {
            border: 2px solid $color-main-button;
            border-radius: 15px 15px 0 15px;
            width: 220px;
            padding: 15px 10px;
            padding-bottom: 5px;
            margin: 15px 0;
            h5 {
              margin-bottom: 5px;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              word-break: break-word;
            }
            p {
              font-size: 14px;
              margin-bottom: 15px;
            }
            span {
              i {
                font-size: 20px;
              }
            }
            progress {
              width: 100%;
            }
          }

          .download-option-preview {
            text-align: center;
            border-top: 1px solid;
            margin-top: 10px;
            padding: 10px 0;
            h6 {
              margin: 0;
            }
          }

          .download-doc-preview-vendor {
            margin: 20px 0;
            border: 2px solid $color-main-button;
            border-radius: 15px 15px 15px 0;
            width: 220px;
            padding: 15px 10px;
            padding-bottom: 5px;
          }
          &.map-image-left {
            align-items: flex-start;
          }
        }
      }
    }

    .message-desc-chat {
      height: 560px;
      overflow: hidden;
      position: relative;
      &.desc-chat-custom {
        height: 100%;
        .message-chat-date {
          height: 558px;
        }
      }
      .message-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid;
        h6 {
          color: $color-main-button;
          font-weight: 700;
          text-transform: uppercase;
          max-width: 350px;
          word-break: break-word;
        }
        p {
          font-size: 12px;
          margin: 0;
        }
      }

      .message-chat-date {
        padding: 0 20px 20px 20px;
        height: 82%;
        overflow-y: auto;
        .message-date {
          position: relative;
          padding: 10px;
          text-align: center;
          p {
            display: inline-block;
            margin: 0;
            font-weight: 600;
            background-color: #fff;
            z-index: 1;
            position: relative;
            padding: 0 20px;
          }
          &::after {
            position: absolute;
            content: "";
            background-color: #000;
            width: 100%;
            height: 1px;
            top: 22px;
            left: 0;
          }
        }
        .chat-desc-sub {
          li {
            border-bottom: none;
            background-color: #fff;
            .username-short-sub {
              width: 50px;
              height: 50px;
              min-width: 50px;
              min-height: 50px;
              border: 1px solid #707070;
              border-radius: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-transform: uppercase;
              font-weight: 700;
              color: #707070;
              margin-right: 10px;
              background-color: #f8f8f8;
            }
            .username-desc {
              .time-add-sub {
                display: flex;
                align-items: center;
                h6 {
                  margin-right: 5px;
                }
                .time-msg {
                  color: #989898;
                  font-weight: 600;
                  font-size: 13px;
                }
              }
              span {
                color: #989898;
                font-size: 12px;
              }
              .usermessage {
                white-space: pre-wrap;
                font-size: 14;
                width: 200px;
                word-break: break-all;
                display: flex;
              }
              .span {
                white-space: pre-wrap;
                width: 200px;
                word-break: break-word;
                color: #000;
              }
            }
          }
        }
      }
      .chat-box {
        border-top: 1px solid #d1d1d1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 13px;
        position: sticky;
        z-index: 1;
        bottom: 0px;
        width: 100%;
        form {
          width: 100%;
          display: flex;
          position: relative;
          border-top: 1px solid #d1d1d1;
          ul {
            position: absolute;
            top: -154px;
            border: 1px solid #d1d1d1;
            border-bottom: none;
            border-left: none;
            li {
              &:last-child {
                border-bottom: none;
              }
              p {
                margin: 0;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                border-radius: 100px;
                border: 1px solid;
                font-size: 12px;
                color: #989898;
              }
            }
          }
        }
        .preview-copy-img {
          position: absolute;
          bottom: 51px;
          display: flex;
          background-color: #f8f8f8;
          width: 100%;
          flex-wrap: wrap;
          overflow-y: auto;
          height: 120px;
          li {
            border: 1px solid #989898;
            overflow: hidden;
            padding: 0;
            border-radius: 10px;
            margin: 10px;
            position: relative;
            img {
              width: 100px;
              min-width: 100px;
              height: 100px;
              min-height: 100px;
              object-fit: cover;
              object-position: top;
            }
            .filesize-main-depth {
              min-width: 100px;
              width: 100px;
              min-height: 100px;
              height: 100px;
              padding: 10px;
              padding-top: 26px;
              h6 {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-bottom: 2px;
                word-break: break-word;
              }
              label {
                font-size: 12px;
              }
            }
            video {
              width: 100px;
              height: 100px;
            }
            p {
              color: $color-main-button;
              font-size: 22px;
              position: absolute;
              top: 0px;
              right: 5px;
              margin: 0;
              z-index: 1;
            }
            span {
              color: $color-main-button;
              padding: 15px;
              font-size: 22px;
              z-index: 1;
            }
            &::after {
              position: absolute;
              content: "";
              top: 0;
              left: 0;
              background-color: rgba(255, 255, 255, 0.6);
              width: 100%;
              height: 100%;
            }
          }
        }
        .chat-icon {
          background-color: #f8f8f8;
          padding: 10px 15px;
          font-size: 20px;
          cursor: pointer;
          span {
            color: $color-main-button;
          }
        }
        textarea {
          border: none;
          border-left: 1px solid #d1d1d1;
          border-right: 1px solid #d1d1d1;
          width: 100%;
          padding: 13px 28px;
          resize: none;
          &:focus {
            outline: none;
            border: none;
            border-left: 1px solid #d1d1d1;
            border-right: 1px solid #d1d1d1;
          }
        }
      }
    }
  }
}

.border-msg-right {
  border-right: 1px solid #d1d1d1;
}
