@import "../Styles/variables.scss";

//body
body {
  background-color: #f8f8f8;
  .form-control {
    &:focus {
      outline: none;
      box-shadow: none;
    }
    .react-date-picker__wrapper {
      border: none;
    }
    .react-date-picker__calendar {
      width: 100%;
    }
  }
  button {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.google-custom {
  border-radius: 100px;
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  img {
    width: 100%;
  }
}

.capital-case {
  text-transform: capitalize !important;
}

//forms
.form-group {
  position: relative;
  label {
    color: #000000;
    font-size: 13px;
    margin-bottom: 8px;
    font-weight: 500;
    text-transform: uppercase;
    sup {
      color: #e25e52;
    }
  }
  .form-control {
    border: 1px solid #d1d1d1;
    height: 40px;
    border-radius: 0px;
    font-size: 13px;
  }
  .form-textarea {
    min-height: 120px;
    resize: none;
  }
  .clock-icon {
    position: absolute;
    top: 40px;
    right: 10px;
  }
}

//colors
.orange {
  color: #e25e52;
}

.black {
  color: #000;
}

.disable-btn {
  opacity: 0.5;
}

//button list group
.group-btn-main-list {
  .border-list-button {
    min-height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e25e52;
    padding: 20px;
    button {
      min-width: 194px;
    }
  }
}

//buttons
.orange-outline-btn {
  border: 1px solid #e25e52;
  color: #e25e52;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  // text-transform: uppercase;
  padding: 6px 20px;
  min-width: 150px;
  &:hover,
  &:focus {
    background-color: #e25e52;
    color: #fff;
    a {
      color: #fff !important;
    }
  }
}

.orange-outline-btn-nohover {
  border: 1px solid #e25e52;
  color: #e25e52;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: 150px;
}

.hyper-link-btn {
  border: none;
  color: #000;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0px;
  &:hover,
  &:focus {
    color: #e25e52;
  }
}

.orange-btn {
  border: 1px solid #e25e52;
  color: #fff !important;
  background-color: #e25e52;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: 150px;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: #fff;
    color: #e25e52 !important;
  }
}

.orange-btn-nohover {
  border: 1px solid #e25e52;
  color: #fff;
  background-color: #e25e52;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px 20px;
  min-width: 150px;
}

//badge
.badge {
  background-color: #e25e52;
  color: #fff;
}

//main-body
.main-body {
  display: flex;
  flex-wrap: wrap;
  background-color: #f8f8f8;
  .main-content {
    width: calc(100% - 250px);
    margin-left: 250px;
    .main-header {
      background-color: #ffffff;
      padding: 15px;
      margin: 15px 0px;
      border: 0.5px solid #ced4da;
      .header-top-left {
        display: flex;
        align-items: center;
        justify-content: left;
        .toggle-btn {
          margin-right: 15px;
          display: none;
          button {
            border: 0px;
            background-color: #e25e52;
            color: #fff;
            padding: 5px 10px;
          }
        }
        .header-top-search {
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
          button {
            background-color: transparent;
            color: #e25e53;
            border: none;
            padding: 0px;
            height: 25px;
            width: 25px;
            padding-right: 10px;
          }
          .form-control {
            border-radius: 0px;
            border-color: #00000029;
            border: 0px;
            height: 30px;
            border-bottom: 1px solid #777777;
            padding-left: 0px;
            font-size: 14px;
            font-weight: 500;
            background-color: transparent;
          }
        }
      }

      .header-top-menu {
        ul {
          margin: 0px;
          padding: 0px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          li {
            list-style: none;
            margin: 0px 15px;
            a {
              color: #000000;
              font-size: 16px;
              i {
              }
              &.header-addtocart-item {
                position: relative;
                span {
                  background-color: #e25e52;
                  font-size: 10px;
                  position: absolute;
                  top: -7px;
                  right: -11px;
                  color: #fff;
                  border-radius: 100%;
                  min-width: 20px;
                  min-height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
    .main-footer {
      text-align: right;
      margin-top: 10px;
      p {
        margin-bottom: 0px;
        color: #000000;
        font-size: 13px;
        margin-bottom: 8px;
        font-weight: 500;
      }
    }
    .admin-card {
      .admin-card-head {
        h2 {
          color: #000000;
          text-transform: capitalize;
          font-size: 18px;
          margin-bottom: 15px;
        }
      }
      .admin-card-box {
        background-color: #ffffff;
        padding: 15px;
        border: 0.5px solid #ced4da;
        .stripe-email {
          display: flex;
          margin-bottom: 50px;
          input {
            border: 1px solid #d1d1d1;
            height: 40px;
            border-radius: 0px;
            font-size: 13px;
            margin-right: 20px;
            padding: 6px 12px;
            width: 100%;
          }
          .custom-error {
            position: absolute;
            top: 42px;
          }
          button {
            white-space: nowrap;
            min-width: auto;
          }
        }
      }
    }
  }
}

//Custom Radio Buttons
.custon-radio {
  display: block;
  position: relative;
  padding-right: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  user-select: none;
  color: #989898 !important;
  margin-right: 15px;
}
.custon-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custon-radio .checkmark {
  position: absolute;
  top: 1px;
  right: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
}

.custon-radio input:checked ~ .checkmark {
  background-color: #e25e52;
}
.custon-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custon-radio input:checked ~ .checkmark:after {
  display: block;
}
.custon-radio .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.circle-radio {
  .checkmark {
    background-color: $white;
    border: 1px solid rgba($primary, 1);
  }
  .checkmark:after {
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $white;
    margin-top: -4px;
    margin-left: -4px;
  }
}

.custom-radio-inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // margin-left: 60px;
}

.label-radio-inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

//Custom Radio Buttons end

//custom-radio-input
.custom-radio-input {
  display: flex;
  gap: 10px;
  p {
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      display: contents;
      input {
        margin-left: 5px;
        accent-color: #e25e52;
      }
      @supports (-moz-appearance: none) {
        input[type="radio"] {
          margin-top: 4px;
        }
      }
      input[type="radio"]:after {
        width: 18px;
        height: 18px;
        border-radius: 100px;
        top: -6px;
        left: -1px;
        position: relative;
        background-color: white;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 1px solid #e25e52;
      }

      input[type="radio"]:checked:after {
        width: 18px;
        height: 18px;
        border-radius: 100px;
        top: -6px;
        left: -1px;
        position: relative;
        background-color: #e25e52;
        content: "";
        display: inline-block;
        visibility: visible;
        border: 1px solid #e25e52;
      }
    }
  }
}

//vendor-table
.vendor-table {
  .vendor-table-head {
    .entries-drop {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      margin-right: 10px;
      label {
        color: #000000;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        margin-right: 10px;
        margin-bottom: 0px;
      }
      .entries-controls {
        border: 1px solid #d1d1d1;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      p {
        color: #000000;
        font-size: 13px;
        margin-bottom: 0px;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
      }
    }
    .vendor-table-head-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      .vendor-table-search {
        margin-bottom: 15px;
        .form-control {
          border: 1px solid #d1d1d1;
          border-radius: 0px;
          font-size: 13px;
          text-transform: uppercase;
          font-weight: 500;
          width: auto;
          height: 25px;
        }
      }
      .download-statement-btn {
        margin-bottom: 15px;
      }
    }
  }
  .vendor-table-body {
    .table {
      thead {
        background-color: rgba(226, 94, 82, 0.1);
        tr {
          td {
            color: #989898;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            vertical-align: middle;
          }
        }
      }
      tbody {
        tr {
          td {
            color: #000000;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            vertical-align: middle;
            white-space: nowrap;
            p,
            h6 {
              margin-bottom: 0px;
            }
            a {
              color: #e25e52;
              &:hover {
                color: #000000;
              }
            }
            .vendor-table-btn {
              display: flex;
              align-items: center;
              gap: 10px;
              .orange-a {
                margin-right: 5px;
              }
              .orange-outline-btn {
                min-width: auto;
                font-size: 12px;
                text-transform: capitalize;
                padding: 2px 20px;
              }
            }
          }
        }
      }
      .vendor-table-check {
        .custon-radio {
          margin: 0px;
          padding: 0px;
          display: block;
          .checkmark {
            left: 0;
            top: -8px;
            border: 1px solid #989898;
            border-radius: 0px;
            background-color: #fff;
            &:after {
              border-color: #e25e52;
            }
          }
        }
      }
    }
  }
}

//paymentdetails-modal
.paymentdetails-modal {
  background-color: #00000059;
  .modal-dialog {
    .modal-content {
      border-radius: 0px;
      border: 0px;
      .modal-header {
        border-bottom: 0px;
        position: relative;
        padding: 25px 20px 5px;
        .paymentdetails-heading {
          text-align: center;
          width: 100%;
          h4 {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 0px;
          }
        }
        .close {
          position: absolute;
          top: 22px;
          right: 15px;
          padding: 0px;
          margin: 0px;
          color: #000;
          opacity: 1;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      .modal-body {
        padding-bottom: 25px;
        .paymentdetails-table {
          .table {
            tbody {
              tr {
                td {
                  border: 1px solid #f3f2f2;
                  h5 {
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 14px;
                    margin-bottom: 3px;
                    color: #000;
                  }
                  p {
                    font-size: 12px;
                    margin-bottom: 0px;
                    color: #000;
                    span {
                      text-transform: uppercase;
                      display: block;
                      &.green {
                        color: #19bf00;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.plant-type-option {
  text-align: center;
  max-width: 250px;
  // margin: 0px auto 20px;
  .dropdown {
    // margin-top: 30px;
    .btn {
      background-color: #f4f4f4;
      border-radius: 0px;
      color: #acacac;
      font-weight: 500;
      border-color: #f4f4f4;
      margin: 0px auto;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      position: relative;
      .add-icon {
        position: absolute;
        top: 7px;
        right: 7px;
        color: #e25d56;
        content: " ";
      }
      &:after {
        display: none;
      }
      &:focus {
        box-shadow: none;
      }
    }
    ul {
      padding: 0;
      width: 100%;
      li {
        padding: 5px;
        cursor: pointer;
        a {
          color: #000;
        }
        &.active {
          background-color: #e25d56;
          color: #fff;
        }
        &:hover {
          background-color: #e25d56;
          color: #fff;
        }
      }
    }
    .form-field-error {
      color: red;
      font-size: 13px;
    }
  }
}

.form-field-error {
  color: red;
  font-size: 13px;
}

//success message
.success-msg {
  background: green;
  padding: 5px 15px;
  border-radius: 4px;
  display: inline-block;
  p {
    color: #fff;
    font-size: 14px;
    margin: 0px;
  }
}

.pendingColor {
  color: #ffb075;
}

.rejectedColor {
  color: #ff0000;
}

.approvedColor {
  color: #00c12a;
}

.colorTheme {
  color: #e25e52;
}

.f600 {
  font-weight: 600;
}
.one-one-metting {
  .meeting-main {
    border: 1px solid #d1d1d1;
    padding: 20px;
    border-radius: 0px 20px 20px 20px;
    width: 330px;
    box-shadow: 0px 4px 10px 0px #dedcdc;
    position: relative;
    margin-bottom: 40px;
    &.right-meeting {
      margin: 20px 0 40px auto;
      border-radius: 20px 0px 20px 20px;
    }
    p {
      margin-bottom: 5px;
    }
    .time-meeting {
      position: absolute;
      bottom: -30px;
      right: 12px;
      font-size: 12px;
      color: #808080;
    }
  }
}

.white-nowrap {
  white-space: nowrap;
}

@media only screen and (max-width: 1024px) {
  //main-body
  .main-body {
    .main-content {
      width: 100%;
      margin-left: 0px;
    }
  }
}

@media only screen and (max-width: 767px) {
  //main-body
  .main-body {
    .main-content {
      .main-header {
        .header-top-search {
          margin-bottom: 15px;
        }
      }
      .main-footer {
        text-align: center;
      }
    }
  }
  //vendor-table
  .vendor-table {
    .vendor-table-head {
      .vendor-table-head-right {
        justify-content: flex-start;
        .vendor-table-search {
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  //main-body
  .main-body {
    .main-content {
      .main-header {
        .header-top-left {
          .header-top-search {
            margin-bottom: 15px;
          }
        }
      }
      .main-footer {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  //main-body
  .main-body {
    .main-content {
      .main-header {
        .header-top-left {
          .toggle-btn {
            display: block;
          }
        }
      }
    }
  }
}

.files-error {
  color: red;
  font-size: 14px;
  margin-bottom: 0;
}

//calendar modal ------------------
.calendar-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        border: none;
        p {
          margin: 0;
        }
      }
      .modal-body {
        padding-bottom: 40px;
        .modal-btn-group {
          text-align: center;
          button {
            margin: 0px 15px;
            border-radius: 0;
            &:hover {
              border-color: $color-main-button;
            }
          }
        }
        .details-slot {
          text-align: center;
          margin-bottom: 20px;
          p {
            margin: 0;
            text-transform: uppercase;
            font-size: 14px;
            margin-bottom: 5px;
          }
          h5 {
            font-weight: 400;
            margin-top: 5px;
            font-size: 18px;
          }
          button {
            margin: 0 6px;
          }
        }
        .button-center {
          text-align: center;
        }
        .schedule-show-b {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #ccc;
          padding: 12px 10px;
          text-transform: uppercase;
          font-size: 14px;
          &.active {
            background-color: #fcefed;
          }
          p {
            margin: 0;
            width: 100%;
          }
          .schedule-check {
            display: flex;
            align-items: center;
            width: 100%;
            p {
              margin: 0;
              margin-left: 5px;
            }
          }
        }
        .meeting-p {
          font-size: 14px;
          text-transform: uppercase;
        }
      }
    }
  }
}

.slot-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    text-transform: uppercase;
  }
}
.pdf-icon-tag {
  color: #e25e52;
  font-size: 20px;
}
.scroll-infinite {
  min-height: 230px;
  height: 230px;
  overflow: auto;
}
.load-button {
  color: #e25e52;
}

.address-privacy {
  margin: 20px 0;
  p {
    margin-bottom: 0;
  }
}

.border-add-new {
  border-bottom: 1px solid #ccc;
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 10px 0;
  margin: 15px 0;
  input {
    border: 1px solid #d1d1d1;
  }
}

//zoom calnedar --------------------
#ZoomMeetingCalendarModal {
  max-width: 100% !important;
  //height: 100% !important;
}
.rbc-calendar {
  height: 100%;
  padding-bottom: 40px;
}

//custom radio ---------------------
.product-detail-radio {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .form-check {
    margin-right: 20px;
    label {
      display: flex;
      align-items: center;
      input {
        width: 14px;
        height: 14px;
        &:focus {
          outline: none;
        }
      }
      input[type="radio"] {
        /* remove standard background appearance */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /* create custom radiobutton appearance */
        display: inline-block;
        /* background-color only for content */
        background-clip: content-box;
        border: 1px solid #e25e52;
        background-color: #fff;
        border-radius: 50%;
      }

      /* appearance for checked radiobutton */
      input[type="radio"]:checked {
        background-color: #e25e52;
      }
    }
  }
}

.message-popup {
  ul {
    padding: 10px;
    list-style: none;
    display: flex;
    align-items: flex-start;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    flex-wrap: wrap;
    height: 150px;
    overflow: auto;
    li {
      border: 2px solid #ccc;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 2px 5px;
      span {
        border-left: 1px solid #ccc;
        padding-left: 5px;
        color: red;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .form-group {
    textarea {
      resize: none;
      height: auto !important;
    }
  }
}

.width-td {
  white-space: normal !important;
}

.ReactCrop__image {
  height: 260px !important;
  width: 260px !important;
  object-fit: cover;
}

.alert-fixed {
  position: fixed;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  z-index: 999;
  top: 50px;
  right: 50px;
  p {
    margin: 0px;
  }
  &.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    margin: 0px;
  }
}

.notify-top {
  text-align: center;
  padding: 10px 0px;
  margin-bottom: 0px;
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  .profile-status {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    &:after {
      content: "";
      position: absolute;
      right: -21px;
      top: 50%;
      margin-top: -12px;
      height: 26px;
      width: 1px;
      background-color: #000;
      opacity: 0.2;
    }
    .head-progress-bar {
      width: 50px;
      height: 50px;
    }
    p {
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: 400;
      color: #000;
    }
  }
  .notify-alert-head {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    span {
      font-size: 16px;
    }
    a {
      color: #e25e52;
      text-decoration: underline;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.represent-status {
  list-style: none;
  position: absolute;
  padding: 0;
  top: 47px;
  left: 4px;
  margin: 0;
  li {
    display: flex;
    align-items: center;
    .color-bar {
      width: 10px;
      height: 10px;
      background-color: #e25e52;
      margin-right: 4px;
    }
    .grey-color {
      background-color: grey;
    }
    .green-color {
      background-color: #00c12a;
    }
    .purple-color {
      background-color: #0423ff;
    }
    .yellow-color {
      background-color: #e4a900;
    }
    p {
      margin: 0;
      font-size: 10px;
    }
  }
}

.search-pills-category {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #e25e52;
  margin-bottom: 10px;
  li {
    border: 1px solid #e25e52;
    border-radius: 5px;
    padding: 0px 4px;
    cursor: pointer;
    margin-right: 5px;
    text-transform: uppercase;
    span {
      border-left: 1px solid #e25e52;
      padding-left: 4px;
      margin-left: 4px;
    }
    &:hover {
      background-color: #ffebea;
    }
  }
}

.variant-combination {
  min-height: 460px;
  .form-group {
    .variant-img {
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        width: 60%;
      }
    }
  }
}

.note-ecom {
  color: #fe504f;
  i,
  strong {
    margin-right: 5px;
  }
}
.react-calendar__navigation__arrow {
  font-size: 20px !important;
  color: #e25e52 !important;
}

.react-calendar__month-view__weekdays__weekday {
  color: #e25e52 !important;
}

.react-date-picker {
  margin-right: 10px;
  text-transform: uppercase;
  height: 33px;
}

.react-calendar__tile--now {
  background: #e25e5233 !important;
}

.react-date-picker__calendar {
  z-index: 12 !important;
}

.react-time-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup__input {
  width: 35px !important;
  text-align: center !important;
}

.calendar-invitee-list {
  padding: 0;
  list-style: none;
  max-height: 100px;
  overflow: auto;
  height: 100%;
}

.note-admin-p {
  font-size: 14px;
  span {
    color: #ff0000;
    font-weight: 600;
  }
}

.crop-image-view {
  height: 260px;
  .ReactCrop {
    height: 260px !important;
    border: 1px solid;
    img {
      height: 260px !important;
      object-fit: contain;
    }
  }
}

.ReactCrop img {
  width: 100% !important;
  height: 100% !important;
}

.add-section-cursor {
  cursor: pointer;
  color: #e25e53;
}

.border-seprate {
  padding-bottom: 15px;
}

.border-table-status {
  border: 1px solid #e25e53;
  .table {
    margin: 0;
  }
}

.seminar-section {
  margin-bottom: 10px;
  //seminar-info
  .seminar-info {
    border: 1px solid rgba(51, 51, 51, 0.12);
    margin-bottom: 20px;
    padding: 15px 15px 0px;
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        h3 {
          font-size: 16px;
          color: #000;
          font-weight: 500;
          margin-bottom: 3px;
          min-width: 120px;
        }
        p {
          font-size: 16px;
          color: #000;
          font-weight: 400;
          margin-bottom: 0px;
        }
        a {
          font-size: 14px;
          color: #e25e52;
          font-weight: 400;
          text-decoration: underline;
          display: block;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.theme-color {
  color: #e25e53 !important;
}

.frequency {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  .heading {
    margin: 0px;
    min-width: 150px;
  }
  .check-outer {
    margin: 10px;
    border: 1px solid #707070;
    padding: 10px;
    min-width: 120px;
    .outer-label {
      display: block;
      position: relative;
      padding-left: 30px;
      // margin-bottom: 12px;
      cursor: pointer;
      font-size: 12px;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #000;
        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }
    }
    .checkmark:after {
      left: 5px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .outer-label input:checked ~ .checkmark:after {
      display: block;
    }
    .outer-label input ~ .checkmark {
      background-color: #fff;
      border: 2px solid black;
      border-radius: 5px;
    }
    /* When the checkbox is checked, add a blue background */
    .outer-label input:checked ~ .checkmark {
      border: 2px solid #000;
      border-radius: 5px;
    }
  }
}

.black-bg-op {
  background-color: rgba($color: #000000, $alpha: 0.02);
}
.white-bg {
  padding: 10px;
}

.recurring {
  border: 1px solid #707070;
  padding: 10px;
  display: flex;
  overflow-y: scroll;
}

.week-card {
  &.disabled {
    opacity: 0.4;
    border: 1px solid black;
  }
  .week-bottom {
    border: 1px solid rgba($color: #000000, $alpha: 0.5);
    margin-bottom: 5px;
    padding: 5px;
  }
  .day {
    padding: 10px;
    color: #fff;
    text-align: center;
    &.active {
      background-color: #e25e53;
    }
    &.disabled {
      opacity: 0.4;
      border: 1px solid black;
      background-color: #000;
    }
  }
  background: #ffffff;

  // opacity: 0.5;
  width: 280px;
  min-width: 280px;
  margin: 10px;
}

.add-new {
  // width: 120px;
  border: 1px solid rgba($color: #000000, $alpha: 0.5);
  border-radius: 5px;
  padding: 6px 10px;
  // margin: 10px auto;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0;
}

.theme-delete {
  color: #e25e53;
  fill: #e25e53;
  border: none;
  cursor: pointer;
}

.bd-t {
  border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
}

.bb1 {
  border-bottom: 1px solid #ddd;
}

.custom-label-product {
  max-width: 150px;
  margin-right: 10px !important;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
  white-space: nowrap;
  &.label-form {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.tooltip-custom {
  position: relative;
  i {
    color: #e25e53;
    cursor: pointer;
    margin-left: 5px;
    transition: 0.3s;
    font-size: 14px;
  }
  &:hover p {
    display: block;
  }
  &:hover .arrow-down {
    display: block;
  }
  p {
    border-radius: 6px;
    padding: 10px;
    position: relative;
    background-color: #fff;
    z-index: 1;
    width: 190px;
    word-break: break-word;
    text-align: left;
    font-size: 12px;
    text-transform: capitalize;
    box-shadow: 0 2px 5px #cec9c98c;
    border: 1px solid #ddd;
    font-weight: 400;
    display: none;
    margin: 0;
    white-space: normal;
  }
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
    position: absolute;
    bottom: -8px;
    left: 9px;
    display: none;
  }
  .together-tooltip {
    position: absolute;
    bottom: 30px;
    left: -6px;
    z-index: 2;
  }
}

.positionRelative {
  position: relative;
}

.dollar-set {
  position: absolute;
  top: 14px;
  left: 8px;
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
  }
}

.pl25 {
  padding-left: 25px;
}

.border-show-seprate {
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
}

.space-description {
  margin-left: 10px;
}

.colorBlack {
  color: #000 !important;
}

.icon-unique-clock {
  top: 28px !important;
}

.MuiRating-root {
  .MuiRating-label {
    margin-bottom: 0px;
    display: block;
  }
}

.react-time-picker__inputGroup {
  padding: 0 !important;
}
.custom-profie-picture {
  border: 1px solid #d1d1d1;
  padding: 15px;
  text-align: center;
  .user-profile-img {
    img {
      border-radius: 100%;
      margin-top: 15px;
      width: 200px !important;
      height: 200px;
      object-fit: contain;
    }
  }
  button {
    margin: 20px 0;
  }
}

.time-slot-modal {
  background-color: #eaeaea;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 6px;
    li {
      background-color: #fff;
      padding: 8px;
      font-size: 12px;
      text-align: center;
      color: #000;
      margin: 0 10px 10px 0;
      flex-wrap: wrap;
    }
  }
}
.upload-wrapper {
  display: flex;
  li {
    list-style: none;
    margin-right: 30px;
    p {
      i {
        font-size: 18px;
        color: #e36056 !important;
      }
    }
  }
}
.preview-copy-img {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  img {
    width: 100%;
    height: 140px;
    object-fit: contain;
    margin-bottom: 10px;
  }
}
.filesize-main-depth {
  h6 {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.add-btn {
  font-size: 24px !important;
  color: #e36056 !important;
  margin-bottom: 20px;
}

//upload-gallery
.upload-gallery {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  overflow-y: auto;
  padding: 20px 0;
  .upload-gallery-card {
    background-color: rgba(#000000, 0.05);
    border-radius: 10px;
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    position: relative;
    a {
      display: block;
      cursor: pointer;
    }
    video {
      width: 100%;
    }
    img {
      width: 100%;
      object-fit: contain;
    }
    .file-name {
      padding: 10px;
      span {
        color: #000;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        word-break: break-all;
      }
    }
    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      i {
        color: #000;
        font-size: 22px;
      }
      &:hover {
        i {
          color: #e25e52;
        }
      }
    }
  }
}

//upload-digital-product
.upload-digital-product {
  display: flex;
  align-items: center;
  .dropdown {
    margin-left: 8px;
    .dropdown-btn {
      border: 0px;
      padding: 0px;
      outline: none;
      box-shadow: none;
      font-size: 20px;
      color: #e25e52;
      &:hover {
        background-color: transparent;
        color: #e25e52;
      }
      i {
      }
    }
    .dropdown-menu {
      .dropdown-item {
        cursor: pointer;
        display: block;
        i {
          margin-right: 8px;
        }
      }
    }
  }
}
.note-ecom-cate {
  display: inline-block;
  margin-bottom: 10px;
}
.mar32 {
  margin-top: 32px;
  @media only screen and (max-width: 767px) {
    margin-top: 5px;
  }
}

//add product new style ------------
.add-product-button-align {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 15px;
  margin-top: 5px;
  button {
    height: 36px;
  }
}

.make-global-attribute {
  border: 1px solid #d1d1d1;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  .make-global-sub {
    display: flex;
    align-items: center;
    border-right: 1px solid #ddd;
    height: 100%;
    // width: 310px;
    .form-group {
      margin-right: 30px !important;
      // min-width: 50%;
    }
    h5 {
      text-transform: uppercase;
      font-weight: 700;
      margin-right: 30px;
      margin-bottom: 0;
      white-space: nowrap;
      font-size: 12px;
      min-width: 70px;
      word-break: break-word;
    }
    button {
      height: 40px;
    }
  }
}

.add-product-table {
  .table {
    font-size: 14px;
    thead {
      background-color: #e6e6e6;
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          .variant-label {
            display: flex;
            align-items: center;
            gap: 10px;
            border: 1px solid #d1d1d1;
            padding: 6px;
            height: 40px;
            // width: 480px;
            // overflow: auto;
            p {
              background-color: #e6e6e6;
              padding: 4px 10px;
              margin: 0;
              font-size: 12px;
            }
          }
          button {
            height: 40px;
          }
        }
      }
    }
  }
}

.product-global-img {
  border: 1px solid #d1d1d1;
  overflow: hidden;
  width: 80px;
  min-width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.global-img-sm {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
  .hover-global-show {
    display: none;
  }
  &:hover {
    img {
      display: none;
    }
    .hover-global-show {
      display: block;
      background-color: $color-main-button;
      border: 1px solid $color-main-button;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: relative;
      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
      }
      svg {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 0;
      }
    }
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

//custom toggle style -----------
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #e25e52;
}

input:focus + .slider {
  box-shadow: 0 0 1px #e25e52;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-main {
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    margin: 0px;
    font-size: 14px;
    line-height: normal;
    color: $dark;
  }
  label {
    margin: 0;
  }
}

.switch-sm {
  width: 50px;
  height: 22px;
  .slider {
    &::before {
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 1px;
    }
  }
}

.note-ecom-margin {
  display: inline-block;
  margin-bottom: 12px;
}

//theme-button
.theme-button {
  border: 1px solid transparent;
  color: transparent;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 8px 20px;
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  &.radius-btn {
    border-radius: 25px;
  }
  &.radius-sm {
    border-radius: 10px;
  }

  &.btn-lg {
    padding: 14px 20px;
  }

  &.primary-btn {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
  &.dark-outline-btn {
    background-color: transparent;
    border-color: $dark;
    color: $dark;
    svg {
      fill: $dark;
      stroke: $dark;
    }
    &:hover {
      background-color: $dark;
      color: $white;
      svg {
        fill: $white;
        stroke: $white;
      }
    }
  }
  &.primary-outline-btn {
    background-color: #fff;
    border-color: $primary;
    color: $primary;
    svg {
      fill: $primary;
    }
    &:hover {
      background-color: $primary;
      color: $white;
      svg {
        fill: $white;
      }
    }
  }
}

//icon-button
.icon-button {
  border: 1px solid transparent;
  color: transparent;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 100%;
  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }
  &.primary-outline-btn {
    border-color: $primary;
    svg {
      fill: $primary;
      stroke: $primary;
    }
    &:hover {
      background-color: $primary;
      border-color: $primary;
      svg {
        fill: $white;
        stroke: $white;
      }
    }
  }

  &.primary-btn {
    border-color: $primary;
    background: $primary;
    svg {
      fill: $white;
      stroke: $white;
    }
    &:hover {
      background-color: $primary;
      border-color: $primary;
      svg {
        fill: $white;
        stroke: $white;
      }
    }
  }
  &.dark-btn {
    background-color: transparent;
    border: 0px;
    svg {
      fill: $dark;
      stroke: $dark;
    }
    &:hover {
      svg {
        fill: $primary;
        stroke: $primary;
      }
    }
  }
}

//custom-checkbox
.custom-checkbox {
  position: relative;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  padding-left: 32px;
  color: $dark !important;
  font-size: 16px;
  font-weight: 400;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: $white;
    border: 1px solid $dark;
    border-radius: 6px;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid $dark;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

//header-parent
.header-parent {
  background-color: #ffeeed;
  padding: 15px 0;
  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-logo {
      img {
        width: 160px;
        height: auto;
      }
    }
    .logout-btn {
    }
  }
}

.fixed-btns {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 999;
  display: flex;
  flex-flow: column;
  gap: 10px;
  align-items: flex-end;
  .icon-button {
    border-radius: 25px;
    padding: 6px 10px;
    text-transform: capitalize;
    a {
      color: $white;
      display: block;
      display: flex;
      gap: 10px;
    }
    span {
      display: none;
    }
    &:hover {
      width: auto;
      span {
        display: inline-block;
      }
    }
  }
}

//theme-modal
.theme-modal {
  background-color: rgba(#000000, 0.5);
  .modal-dialog {
    .modal-content {
      border-radius: 30px;
      .modal-closer {
        border: 0px;
        padding: 0px;
        background-color: transparent;
        display: inline-block;
        width: fit-content;
        position: absolute;
        right: 16px;
        top: 19px;
        z-index: 1;

        svg {
          width: 30px;
          height: 30px;
          min-width: 30px;
        }
      }

      .modal-body {
        padding: 40px;
        .section-process {
          .heading {
            margin-bottom: 30px;
            h2 {
              font-size: 24px;
              font-weight: 700;
              color: $dark;
              margin-bottom: 0px;
              padding-bottom: 12px;
              position: relative;
              &:after {
                content: "";
                position: absolute;
                left: 0px;
                bottom: 0px;
                height: 4px;
                width: 65px;
                background-color: rgba($primary, 1);
              }
            }
          }

          .section-content {
            margin-bottom: 20px;
            ul {
              padding: 0px;
              margin: 0px;
              position: relative;
              &:after {
                content: "";
                position: absolute;
                left: 0px;
                top: 8px;
                width: 1px;
                height: calc(100% - 20px);
                border-left: 1px dashed rgba($primary, 1);
              }

              li {
                list-style: none;
                position: relative;
                padding-left: 26px;
                padding-bottom: 22px;
                &:nth-last-child(1) {
                  padding-bottom: 0px;
                }

                &:after {
                  content: "";
                  position: absolute;
                  left: -5px;
                  top: 6px;
                  background-color: rgba($primary, 1);
                  width: 10px;
                  height: 10px;
                  min-width: 10px;
                  border-radius: 100%;
                }

                p {
                  font-size: 16px;
                  font-weight: 500;
                  color: $dark;
                  margin-bottom: 0px;
                }

                &:nth-last-child(1) {
                  border: 0px;
                }
              }
            }
          }

          .bottom-info {
            p {
              font-size: 18px;
              font-weight: 600;
              color: $dark;
              margin-bottom: 0px;
            }
          }
        }

        .brief {
          p {
            font-size: 18px;
            font-weight: 400;
            color: $dark;
            margin-bottom: 0px;
          }
        }

        .inline-button {
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: center;
        }
      }
    }
  }
  &.modal-show {
    display: block;
  }
}

.step-link {
  color: rgba($primary, 1);
  &:hover {
    text-decoration: underline;
    color: rgba($primary, 1);
  }
}

//theme-table
.theme-table {
  table {
    tr {
      td {
        padding: 10px 15px;
        color: $white;
        font-size: 16px;
        border: 0px;
        vertical-align: middle;
      }
    }
    thead {
      tr {
        td {
          background-color: rgba($primary, 1);
          font-weight: 600;
          &:nth-child(1) {
            border-top-left-radius: 10px;
          }
          &:nth-last-child(1) {
            border-top-right-radius: 10px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          color: $dark;
          font-weight: 400;
          .image-with-uploader {
            display: flex;
            align-items: center;
            gap: 15px;
            .placeholder-img {
              img {
                width: 34px;
                height: 34px;
                min-width: 34px;
                object-fit: cover;
              }
            }
            .upload-link {
              position: relative;
              display: inline-block;
              font-size: 16px;
              line-height: normal;
              color: rgba($primary, 1);
              font-weight: 500;
              cursor: pointer;
              input {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                opacity: 0;
              }
            }
          }

          .variant-input {
            max-width: 200px;
          }
        }
        &:nth-child(odd) {
          background-color: rgba(226, 94, 82, 0.05);
        }
      }
    }
  }
}

//link-button
.link-button {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
  svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
  }
  &:hover {
    background-color: transparent;
  }
  &.primary-btn {
    color: rgba($primary, 1);
    font-size: 14px;
    line-height: normal;
    svg {
      fill: $primary;
      stroke: $primary;
    }
  }
}

//input[type=file]
input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

//z-index css
.zindex-2 {
  z-index: 2;
}

//custom-popover
.custom-popover-open {
  position: relative;
}

.custom-popover {
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-width: 250px;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 43px;
  .heading {
    margin-bottom: 20px;
    text-align: center;
    p {
      font-size: 14px;
      font-weight: 400;
      color: $dark;
      margin-bottom: 0px;
    }
  }

  .inline-button {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    .theme-button {
      font-size: 14px;
      line-height: normal;
      min-width: 85px;
      padding: 5px 0px;
    }
  }
}
