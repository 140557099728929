.main-body{
    .main-content{
        .admin-card{
            .admin-card-box{
                padding: 15px 35px 15px;
            }
        } 
    } 
} 

.subscription{
    .subscription-heading{
        text-align: center;
        margin-top: 20px;
        margin-bottom: 35px;
        h2{
            color: #000000;
            text-transform: uppercase;
            font-size: 18px;
            margin-bottom: 5px;
        }
        p{
            color: #000000;
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 15px;
            font-weight: 500;
        }
    }
    .subscription-package{
        .subscription-box{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #989898;
            margin-bottom: 15px;
            min-height: 490px;
            position: relative;
            .subscription-box-head{
                padding: 20px 5px;
                text-align: center;
                h4{
                    color: #000000;
                    text-transform: uppercase;
                    font-size: 18px;
                    margin-bottom: 5px;
                }
                p{
                    color: #000000;
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                h2{
                    color: #000000;
                    text-transform: uppercase;
                    font-size: 30px;
                    margin-bottom: 0px;
                    span{
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }
            .subscription-box-mid{
                ul{
                    margin: 0px;
                    padding: 0px;
                    text-align: center;
                    li{
                        list-style: none;
                        border-top:1px solid #ececec;
                        padding: 8px 5px;
                        &:nth-last-child(1){
                            border-bottom:1px solid #ececec;
                        }
                        p{
                            margin-bottom: 0px;
                            color: #000000;
                            text-transform: uppercase;
                            font-size: 12px;
                        }
                        i{
                            color: #E25E52;
                        }
                    }
                }
            }
            .subscription-box-bottom{
                padding: 20px 5px;
                text-align: center;
                display: block;
                position: absolute;
                bottom: 20px;
                left: 0;
                right: 0;
                .orange-outline-btn{
                    font-size: 12px;
                    padding: 8px 20px;
                    margin-top: 15px;
                }
                p{
                    margin-top: 10px;
                    font-size: 12px;
                    margin-bottom: 0px;
                }
            }
        }
    }
}