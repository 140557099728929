@import "../../Styles/variables.scss";

.onboarding-page {
    padding-top: 40px;
    padding-bottom: 40px;
    .page-heading {
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        gap: 10px;

        h2 {
            font-size: 30px;
            font-weight: 700;
            color: $dark;
            margin-bottom: 0px;
        }

        .back-btn {
            min-width: 60px;
        }
    }

    .page-inner {
        min-height: calc(100vh - 300px);
        .stripe-address{
            margin-bottom: 20px;
            a{
                color: $dark;
                font-size: 16px;
                font-weight: 500;
                color: rgba($primary, 1);
                text-decoration: underline;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .form-group {
        label {
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 400;
            color: $dark;
            margin-bottom: 5px;
        }

        .form-control {
            border-color: $grey;
            border-radius: 10px;
            padding: 12px 15px;
            font-size: 16px;
            color: $dark;
            height: 50px;
            width: 100%;
            font-weight: 400;

            sup {
                font-size: 14px;
            }
        }

        textarea {
            height: auto !important;
        }

        .react-tel-input {
            .form-control{
                padding-left: 50px;
            }
            .flag-dropdown {
                border-radius: 8px 0 0 8px;
            }

            .selected-flag:focus,
            .selected-flag:hover {
                background-color: transparent;
            }

            .flag-dropdown.open .selected-flag {
                background-color: transparent;
            }
        }
    }

    .action-btn {
        display: flex;
        gap: 15px;
    }

    .custon-radio {
        display: inline-block;
        padding-right: 0px;
        margin-right: 0px;
        padding-left: 32px;
        color: $dark !important;
        font-size: 16px;
        font-weight: 400;

        .checkmark {
            left: 0;
            border: 1px solid rgba($dark, 0.12);
            height: 24px;
            width: 24px;
            background-color: $white;

            &:after {
                background: $primary;
                border-radius: 100%;
                width: 16px;
                height: 16px;
                min-height: 16px;
                min-width: 16px;
                left: 50%;
                top: 50%;
                margin-left: -8px;
                margin-top: -8px;
                border: 0px;
            }
        }

        input:checked~.checkmark {
            background-color: $white;
            border-color: $primary;
        }
    }

    .form-inner-hrading {
        margin-bottom: 15px;

        .name {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;

            h4 {
                font-size: 16px;
                font-weight: 600;
                color: $dark;
                margin-bottom: 0px;
            }
        }

        .tooltip-box {
            svg {
                width: 18px;
                height: 18px;
                min-width: 18px;
            }
        }

        .inline-radio {
            display: flex;
            gap: 20px;
        }
    }

    //basic-multi-select
    .basic-multi-select {
        .select__control {
            border-color: $grey;
            min-height: 50px;
            border-radius: 10px;
            box-shadow: none;
        }
    }

    //upload-img-btn
    .upload-img-btn {
        position: relative;
        display: inline-block;

        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
           
        }
        input[type=file]::-webkit-file-upload-button {
            display: none;
          }
    }

    //onboard-img-section
    .onboard-img-section {
        text-align: center;

        .onboard-img {
            margin-bottom: 30px;

            img {
                width: 150px;
                height: 150px;
                min-width: 150px;
                object-fit: cover;
                border-radius: 100%;
                border: 1px solid rgba($dark, 0.12);
            }
        }
    }

    //payment-configuration-section
    .payment-configuration-section {
        .section-heading {
            margin-bottom: 20px;

            h3 {
                font-size: 20px;
                font-weight: 600;
                color: $dark;
                margin-bottom: 4px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: $dark;
                margin-bottom: 0px;
            }
        }

        .configuration-card {
            border-radius: 30px;
            border: 1px solid rgba(51, 51, 51, 0.12);
            background: $white;
            padding: 20px;
            margin-bottom: 20px;
            min-height: 220px;

            .name {
                h4 {
                    font-size: 18px;
                    font-weight: 600;
                    color: $dark;
                    margin-bottom: 12px;
                }
            }

            .brief {
                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: $dark;
                    margin-bottom: 12px;

                    a {
                        color: $primary;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .stripe-store {
            background: rgba(226, 94, 82, 0.10);
            display: flex;
            align-items: center;
            gap: 10px;
            border-radius: 25px;
            width: fit-content;
            padding-right: 20px;
            margin-bottom: 30px;

            .fireworks-icon {
                width: 40px;
                height: 40px;
                min-width: 40px;
                border-radius: 100%;
                background-color: $primary;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 25px;
                    height: 25px;
                    min-width: 25px;
                }
            }

            .brief {
                padding: 8px 0px;
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: $dark;
                    margin-bottom: 0px;
                }
            }
        }
    }

    //onboarding-subscription-section
    .onboarding-subscription-section {
        .section-heading {
            margin-bottom: 20px;

            h3 {
                font-size: 20px;
                font-weight: 600;
                color: $dark;
                margin-bottom: 4px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: $dark;
                margin-bottom: 0px;
            }
        }

        .onboarding-subscription-card {
            border-radius: 30px;
            border: 1px solid rgba(51, 51, 51, 0.12);
            background: $white;
            padding: 30px;
            margin-bottom: 20px;

            .card-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 20px;

                .name {
                    position: relative;
                    padding-bottom: 10px;

                    &::after {
                        content: "";
                        position: absolute;
                        left: 0px;
                        bottom: 0px;
                        height: 3px;
                        width: 62px;
                        background-color: $primary;
                    }

                    h2 {
                        font-size: 32px;
                        font-weight: 600;
                        color: $dark;
                        margin-bottom: 0px;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        color: $dark;
                        margin-bottom: 0px;
                    }
                }

                .price {
                    text-align: right;

                    h3 {
                        font-size: 24px;
                        font-weight: 600;
                        color: $dark;
                        margin-bottom: 0px;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        color: $dark;
                        margin-bottom: 0px;
                        opacity: 0.6;
                    }
                }
            }

            .card-mid {
                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: $dark;
                    margin-bottom: 10px;
                }

                .item {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    margin-bottom: 4px;

                    &:nth-last-child(1) {
                        margin-bottom: 0px;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                    }

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        color: $dark;
                        margin-bottom: 0px;
                    }
                }
            }


        }
    }

    //store-preview-card
    .store-preview-card {
        .card-top {
            border-radius: 30px;
            border: 1px solid rgba(51, 51, 51, 0.12);
            background: #FFF;
            margin-bottom: 20px;
            padding: 30px;

            .store-detail {
                display: flex;
                align-items: center;
                gap: 20px;

                .store-img {
                    img {
                        width: 110px;
                        height: 110px;
                        min-width: 110px;
                        border-radius: 100%;
                    }
                }

                .content {
                    .name {
                        h3 {
                            font-size: 26px;
                            font-weight: 700;
                            color: $dark;
                            margin-bottom: 10px;
                        }

                        .star-icons {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            margin-bottom: 10px;

                            svg {
                                width: 20px;
                                height: 20px;
                                min-width: 20px;
                            }
                        }
                    }

                    .brief {
                        p {
                            font-size: 16px;
                            font-weight: 400;
                            color: $dark;
                            margin-bottom: 10px;
                        }
                    }

                }
            }

            .store-detail-right {
                display: flex;
                flex-flow: column;
                align-items: flex-end;
                .top-actions {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 15px;
                }

                .time-info {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .item {
                        p {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            font-size: 16px;
                            font-weight: 400;
                            color: $dark;
                            margin-bottom: 10px;

                            svg {
                                width: 20px;
                                height: 20px;
                                min-width: 20px;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 991px) and (min-width: 768px){
            .card-top {
                .store-detail {
                   margin-bottom: 20px;
                }
    
                .store-detail-right {
                    align-items: flex-start;
                }
            }
        }
    }

    //sample-products-section
    .sample-products-section{
        .section-heading{
            margin-bottom: 20px;
            h2{
                font-size: 26px;
                font-weight: 700;
                color: $dark;
                margin-bottom: 0px;
            }
        }
        .section-inner{

        }
    }
}

//product-card
.product-card{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    margin-bottom: 30px;
    .card-img{
      background-color: rgba($dark, 0.03);
      position: relative;
      img{
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: left;
      }
      .card-tags{
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        span{
          background-color: $primary;
          padding: 5px 15px;
          text-transform: uppercase;
          font-size: 12px;
          color:rgba($color-white, 1);
          border-radius: 2px;
        }
      }
  
      .image-status-label{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: rgba(0, 0, 0, .4);
        display: flex;
        align-items: center;
        justify-content: center;
        .status-label{
          border: 1px solid rgba($primary, 1);
          color: rgba($color-white, 1);
          background-color: rgba($primary, 1);
          font-size: 12px;
          font-weight: 400;
          text-transform: uppercase;
          padding: 7px 23px;
          cursor: pointer;
            p{
              margin-bottom: 0px;
            }
  
        }
      }
    }
    .card-content{
      padding: 15px;
      min-height: 175px;
      .name{
        margin-bottom: 15px;
        h3{
          font-size: 16px;
          font-weight: 500;
          color: $dark;
          margin-bottom: 0px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-word;
        }
      }
      .price-with-offer{
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
        margin-bottom: 10px;
        .price{
          p{
            font-size: 14px;
            font-weight: 600;
            color:$dark;
            margin-bottom: 0px;
            span{
              font-weight: 400;
              opacity: 0.5;
            }
          }
        }
        .offer-tag{
          span{
            background: rgba(210, 81, 66, 0.15);
            padding: 5px 10px;
            text-transform: uppercase;
            font-size: 12px;
            color:rgba($primary, 1);
            border-radius: 2px;
            display: inline-block;
            font-weight: 500;
            text-align: center;
          }
        }
      }
      
      .rating-info{
        margin-bottom: 10px;
        svg{
            width: 16px;
            height: 16px;
            min-width: 16px;
        }
      }
      .product-owner-info{
        display: flex;
        align-items: center;
        gap: 8px;
        .product-owner-img{
          img{
            width: 20px;
            height: 20px;
            min-width: 20px;
            object-fit: cover;
            border-radius: 100%;
          }
        }
        .product-owner-name{
          p{
            font-size: 12px;
            font-weight: 400;
            color: $dark;
            margin-bottom: 0px; 
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-word;
            span{
              font-weight: 500;
            }
          }
        }
      }
    }
    &:hover{
      .card-img{
        .image-status-label{
          opacity: 1;
        }
      }
    }
  
    @media only screen and (max-width: 567px){
      .card-img{
        img{
          height: 160px;
        }
        .card-tags{
          gap: 5px;
          span{
            padding: 5px 8px;
            font-size: 11px;
          }
        }
    
        .image-status-label{
          .status-label{
            font-size: 11px;
            padding: 5px 8px;
            cursor: pointer;
          }
        }
      }
      .card-content{
        padding: 10px;
        min-height: 160px;
        .name{
          margin-bottom: 10px;
          h3{
            font-size: 14px;
          }
        }
        .price-with-offer{
          margin-bottom: 5px;
          flex-flow: column;
          align-items: flex-start;
          .offer-tag{
            span{
              padding: 5px 8px;
              font-size: 11px;
            }
          }
        }
        .rating-info{
          margin-bottom: 0px;
        }
        .product-owner-info{
          gap: 5px;
        }
      }
    }
  }

  //onboarding-main-section
  .onboarding-main-section{
    padding-top: 60px;
    .onboard-img{
        margin-bottom: 20px;
        img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    .section-contnt{
        .heading{
            margin-bottom: 30px;
            h2{
                font-size: 26px;
                font-weight: 700;
                color: $dark;
                margin-bottom: 0px;
            }
        }
        .section-content{
            margin-bottom: 30px;
            ul{
                padding: 0px;
                margin: 0px;
                li{
                    list-style: none;
                    position: relative;
                    padding-left: 26px;
                    border-left: 1px dashed rgba($primary, 1);
                    padding-bottom: 22px;
                    &:after{
                        content: "";
                        position: absolute;
                        left: -5px;
                        top: 0px;
                        background-color: rgba($primary, 1);
                        width: 10px;
                        height: 10px;
                        min-width: 10px;
                        border-radius: 100%;
                    }
                    h4{
                        font-size: 18px;
                        font-weight: 600;
                        color: $dark;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 16px;
                        font-weight: 400;
                        color: $dark;
                        margin-bottom: 0px;
                    }
                    &:nth-last-child(1){
                        border: 0px;
                    }
                }
            }
        }
    }
  }


   //onboarding-tab
   .onboarding-tab {
    width: 100%;
    ul {
        margin: 0px 0px 20px;
        padding: 0px;
        display: flex;
        gap: 5px;
        overflow-x: auto;
        li {
            width: 100%;
            color: #ababab;
            list-style: none;
            border-bottom: 2px solid #ababab;
            padding-bottom: 4px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 8px;
            position: relative;
            white-space: nowrap;

            svg {
                width: 16px;
                height: 16px;
                min-width: 16px;
            }

            &.active {
                color: $primary;
                border-color: $primary;
                cursor: pointer;
            }

            &.half-active {
                color: $primary;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: -2px;
                    left: 0px;
                    width: 50%;
                    height: 2px;
                    background-color: $primary;
                }
            }
        }
    }
}