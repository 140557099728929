.vendor-table-btn {
  //button
  .orange-btn {
    border: 1px solid #e25e52;
    color: #fff;
    background-color: #e25e52;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 6px 20px;
    min-width: 100px;
    margin-right: 2px;
    &:hover,
    &:focus {
      background-color: #fff;
      color: #e25e52;
    }
  }
  .orange-outline-btn {
    border: 1px solid #e25e52;
    color: #e25e52;
    background-color: transparent;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 6px 20px !important;
    min-width: 100px;
    &:hover,
    &:focus {
      background-color: #e25e52;
      color: #fff;
    }
  }
}